export const S3_BUCKET_URI = process.env.REACT_APP_S3;

export const CALL_US_CONTACT = "1800-202-5878";

export const ADDRESS_DETAILS =
  "56, Mysore Rd, New Timberyard Layout, Telecom Colony, Srinagar, Banashankari, Bengaluru, Karnataka 560026";

export const SUPPORT_EMAIL_ADDRESS = "support@vanshceramics.com";

export const VANSH_CERAMICS_DESKTOP_VIDEO = S3_BUCKET_URI + "/videos/vc.mp4";

export const VANSH_CERAMICS_MOBILE_VIDEO = S3_BUCKET_URI + "/videos/vc_mobile.mp4";

export const ABOUT_US_VIDEO = S3_BUCKET_URI + "/videos/tile_production.mp4";

export const HOME_PAGE_CRAFTMANSHIP_PARA = `At Vansh Ceramics, quality assurance is our top priority, and we
  implement stringent measures to ensure that our tiles meet the
  highest standards of excellence. Our comprehensive quality assurance
  program encompasses various testing methods to evaluate our tile's
  durability, safety, and performance across different applications.
  From assessing water absorption levels to determine their
  suitability for moisture-prone environments like bathrooms and
  kitchens to conducting rigorous breaking strength tests to ensure
  structural integrity, we leave no stone unturned in guaranteeing the
  reliability of our products. Our tiles are exceptionally good at
  providing waterproof surfaces, chemical resistance, thermal shock
  resistance, and resistance to surface abrasion, ensuring longevity
  and performance in diverse settings. Scratch resistance testing
  ensures they can withstand everyday wear and tear, while slip
  resistance tests prevent accidents in wet conditions. Stain
  resistance testing ensures easy cleaning and maintenance, making
  them ideal for spill-prone areas. We are committed to exceeding
  expectations in quality, performance, and environmental
  responsibility. Through our unwavering commitment to quality
  assurance and environmental consciousness, we strive to provide our
  customers with tiles that meet and exceed their expectations,
  delivering unparalleled performance and longevity for any project.`;

export const OFFICE_LATITUDE = 12.950294149652985;
export const OFFICE_LONGITUDE = 77.54505776197985;

export const COLLECTIONS_PAGINATION_LIMIT = 9;

export const VANSH_CERAMICS_VIDEO_THUMBNAIL = S3_BUCKET_URI + "/static-images/gallery/WILSON_ONYX.png";
